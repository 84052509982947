import { useGetQuery } from '../../query'

const useSelectSubscription = () => {

  const {
    data: subscriptionPlan1,
    status: subscriptionPlan1Status
  } = useGetQuery('stripePlans', 'price_1H837ZA9eQ3FzEF5FJW7owGj')

  const {
    data: subscriptionPlan2,
    status: subscriptionPlan2Status
  } = useGetQuery('stripePlans', 'price_1H837aA9eQ3FzEF5SWsPdwAE')

  return {
    subscriptionPlan1,
    subscriptionPlan2
  }
}

export default useSelectSubscription
